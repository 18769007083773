import React, { ReactComponentElement } from 'react';
import { Box, Typography } from 'components/basic';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { ProfileProgress } from './ProfileProgress';

export function ProfileHeader({
  handleClickBack,
  label,
  rightButton,
  progress,
  isTeacher,
}: {
  handleClickBack?: () => void;
  label: string;
  progress?: number;
  rightButton?: ReactComponentElement<any, any>;
  isTeacher?: boolean;
}) {
  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: '55px', md: '88px' },
        bgcolor: '#FCFCFD',
        borderBottom: '1px solid #EAECF5',
      }}>
      <Box
        sx={{
          paddingX: { xs: 2, md: 4 },
          display: 'flex',
          height: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Box
          onClick={handleClickBack}
          sx={{
            display: 'flex',
            minWidth: '100px',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center,',
            gap: '5px',
            cursor: 'pointer',
          }}>
          <ArrowBackIosNewIcon />
          <Typography sx={{ fontSize: { xs: '14px', md: '16px' }, fontWeight: 500 }}>Back</Typography>
        </Box>
        <Typography sx={{ fontSize: { xs: '14px', md: '18px' }, fontWeight: 600 }}>{label}</Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {isTeacher && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                component="pre"
                sx={{
                  fontSize: { xs: '8px', md: '12px' },
                  textAlign: 'right',
                  opacity: 0.5,
                  paddingX: 1,
                  display: { xs: 'none', sm: 'block' },
                }}>
                {progress < 100
                  ? `Your profile is not visible in search.
Fill in your profile`
                  : 'Your profile is visible in search now'}
              </Typography>
              <ProfileProgress value={progress}></ProfileProgress>
            </Box>
          )}
          {!!rightButton ? rightButton : <Box sx={{ minWidth: '100px' }}></Box>}
        </Box>
      </Box>
    </Box>
  );
}
