import { Avatar, Box, Link, Typography } from '@mui/material';
import React, { useState } from 'react';

import { SubDescription, Title } from './Typography';
import { ProfilePageComponentWrapper } from '../ProfilePageComponentWrapper';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
export function ProfileStudio({
  location,
  label,
  website,
  images,
}: {
  location?: string;
  label?: string;
  website?: string;
  images: string[];
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const updateIndex = ({ index: current }: { index: number }) => setIndex(current);
  return (
    <ProfilePageComponentWrapper>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: '16px', md: '24px' } }}>
        <Title>Studio</Title>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',

            gap: '12px',
          }}>
          <Avatar
            sx={{
              width: '64px',
              height: '64px',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',

                gap: '16px',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                }}>
                <Typography
                  sx={{
                    color: '#101828',
                    fontSize: '16px',
                    fontWeight: '600',
                  }}>
                  {label}
                </Typography>
                <SubDescription>{location}</SubDescription>
              </Box>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: '#452D9E',
                  fontSize: '14px',
                }}
                href={website}>
                {website}
              </Link>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            // display: 'grid',
            // gridTemplateColumns: { xs: 'repeat(2,1fr)', md: 'repeat(4,1fr)' },
            // 'repeat(5,1fr)',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '16px',
            // maxWidth: '90vw',
            // justifyContent: { xs: 'center', md: 'flex-start' },
          }}>
          {images?.map((item, index) => {
            if (index > 3) return null;

            return (
              <Box
                sx={{
                  position: 'relative',
                  height: '120px',
                  cursor: 'pointer',
                }}
                onClick={() => setIsOpen(true)}>
                <img
                  key={`${item}-${index}`}
                  style={{
                    objectFit: 'cover',
                    height: '120px',
                    maxWidth: '30vw',
                    borderRadius: '8px',
                    filter: index === 3 ? 'brightness(60%)' : 'brightness(100%)',
                  }}
                  src={item}
                  alt="studio"
                />
                {index === 3 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '42px',
                      display: 'flex',
                      // justifyContent: 'center',
                      flexDirection: 'row',
                      width: '100%',
                    }}>
                    <Typography
                      sx={{
                        marginX: 'auto',
                        color: 'white',
                        fontSize: '24px',
                        fontWeight: '500',
                      }}>
                      +4
                    </Typography>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
        <Lightbox
          controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
          carousel={{ finite: true }}
          on={{ view: updateIndex }}
          open={isOpen}
          index={index}
          close={() => setIsOpen(false)}
          slides={images?.map((item) => ({ src: item, width: 1300, height: 900, imageFit: 'cover' }))}
        />
      </Box>
    </ProfilePageComponentWrapper>
  );
}
