import { Box, Typography } from '@mui/material';
import React from 'react';
import { ProfilePageComponentWrapper } from '../ProfilePageComponentWrapper';
import { TagEllipsis } from 'containers/SettingsProfilePage/components/TagEllipsis';

export function ProfileAboutUs({ about, tags }: { about?: string; tags: string[] }) {
  return (
    <ProfilePageComponentWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: '16px', md: '24px' },
        }}>
        <Typography sx={{ fontWeight: '600', fontSize: '18px', color: '#101828' }}>About me</Typography>
        <Typography sx={{ color: '#667085', fontSize: '14px' }}>{about}</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '12px',
          }}>
          {tags.map((item, index) => (
            // <AboutTag title={item} key={index} />
            <TagEllipsis maxWidth={150} data={item} index={index}></TagEllipsis>
          ))}
        </Box>
      </Box>
    </ProfilePageComponentWrapper>
  );
}

export function AboutTag({ title }: { title: string }) {
  return (
    <Box
      sx={{
        borderRadius: '100px',
        bgcolor: '#F4F3FF',
        border: ' 1px solid #6938EF',

        padding: { xs: '6px', lg: '8px' },
      }}>
      <Typography sx={{ fontSize: { xs: '13px', md: '14px' }, color: '#6938EF' }}>{title}</Typography>
    </Box>
  );
}
