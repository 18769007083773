import React from 'react'
import { Box, Chip } from "@mui/material";

export function TagEllipsis({maxWidth, data, index}:{maxWidth:number; data:string; index:number;}){
     return (
       <Box key={index}>
         <Chip
           label={<EllipsisText maxWidth={maxWidth}>{data}</EllipsisText>}
           sx={{
             border: '1px solid #6938EF',
             backgroundColor: '#F4F3FF',
             position: 'relative',
             padding: '8px',
             maxWidth : maxWidth ,
           }}
         />
       </Box>
     );
}

 const EllipsisText = (props) => {
   const { children,maxWidth } = props;

   return (
     <div
       style={{
         whiteSpace: 'nowrap',
         overflow: 'hidden',
         textOverflow: 'ellipsis',
       }}>
       {children}
     </div>
   );
 };