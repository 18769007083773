import { AppBar, Box, Button, IconButton, Menu, Toolbar, Typography } from 'components/basic';
import {
  AccountCircle,
  Logout,
  Login,
  HeadsetMic,
  CalendarMonthIcon,
  MenuIcon,
  MessageIcon,
  EditIcon,
} from 'components/icons';
import { useAuth } from 'hooks/useAuth';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import routeNames from 'route-names';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { useSelector } from 'react-redux';
import { selectIsMenuVisible } from 'redux/selectors';
import { Logo } from './Logo';
import styled from 'styled-components';

//import PatreonButton from './PatreonButton';
import { useWindowSize } from 'hooks/useWindowSize';
import { MenuButton } from './MenuButton';
import { globalStyles, spacing, themeVariables } from 'theme';
import { CustomAvatar } from 'components/CustomAvatar/CustomAvatar';
import { stringToColor } from 'helpers/stringToColor';
import useUnreadMessages from 'hooks/useUnreadMessages';

const RightMenuBlockS = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const MenuItemLabelS = styled.span`
  display: inline;
  margin-left: 0.5em;
  color: ${globalStyles.textColor};
`;

const ListS = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  min-width: 180px;

  li > a {
    justify-content: flex-start;
    padding: 0.6em;
  }
`;

const LiS = styled.li`
  list-style: none;
  display: flex;
  margin: 0;

  > a {
    flex: 1;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    white-space: nowrap;
    padding: 0.6em 1em;
    min-width: 30px;
    border-radius: ${themeVariables.btnBorderRadius};
  }
`;

const LandingPagelinks = [
  {
    title: 'About',
    url: '#About',
  },
  {
    title: 'Solutions',
    url: '#Services',
  },
  {
    title: 'Gallery',
    url: '#Gallery',
  },
  {
    title: 'FAQ',
    url: '#FAQ',
  },
  {
    title: 'Contacts',
    url: '#Contacts',
  },
];

export default function Header() {
  const {
    user: { model: user },
    ...auth
  } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const size = useWindowSize();
  const length = useUnreadMessages();
  const isMenuVisible = useSelector(selectIsMenuVisible);
  const pathname = location.pathname;
  const [anchorEl, setAnchorEl] = useState(null);
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);
  const showInlineMenu = size.width >= 1024;
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (link) => {
    setAnchorEl(null);
    if (link.url) {
      window.location.href = link.url;
    }
  };

  const handleAccountMenuOpen = (event) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAccountMenuAnchorEl(null);
  };

  function signOut() {
    handleAccountMenuClose();
    auth.signout().then(() => {
      history.push(routeNames.signIn);
      history.go(0);
    });
  }

  const getUserProfileRoute = () => {
    return user.isTeacher() ? routeNames.teacherProfile + '/' + user.id : routeNames.studentProfile + '/' + user.id;
  };

  function getAccountMenu() {
    return [
      <LiS key="View Profile">
        <MenuButton
          variant="text"
          to={getUserProfileRoute()}
          onClick={() => handleAccountMenuClose()}
          title="Account Information">
          <AccountCircle /> <MenuItemLabelS>View Profile</MenuItemLabelS>
        </MenuButton>
      </LiS>,
      <LiS key="Edit Profile">
        <MenuButton
          variant="text"
          to={routeNames.settingsProfile}
          onClick={() => handleAccountMenuClose()}
          title="Account Information">
          <EditIcon /> <MenuItemLabelS>Edit Profile</MenuItemLabelS>
        </MenuButton>
      </LiS>,
      <LiS key="Contacts">
        <MenuButton variant="text" to={routeNames.contact} onClick={() => handleAccountMenuClose()} title="Contacts">
          <HeadsetMic /> <MenuItemLabelS>Contacts</MenuItemLabelS>
        </MenuButton>
      </LiS>,
      <LiS key="Calendar">
        <MenuButton
          variant="text"
          to={{ pathname: 'https://calendar.google.com/calendar' }}
          onClick={() => handleAccountMenuClose()}
          target="_blank"
          rel="nofollow"
          title="Calendar">
          <CalendarMonthIcon /> <MenuItemLabelS>Calendar</MenuItemLabelS>
        </MenuButton>
      </LiS>,
      <LiS key="Sign out">
        <MenuButton variant="text" to="" onClick={signOut} title="Sign out">
          <Logout /> <MenuItemLabelS>Sign out</MenuItemLabelS>
        </MenuButton>
      </LiS>,
    ];
  }

  function getLandingPageMenuLinks() {
    if (pathname !== routeNames.landing) return null;

    return LandingPagelinks.map((link) => (
      <LiS key={link.title}>
        <MenuButton
          style={{ padding: '0.6em 1em', margin: '0.2em' }}
          component="a"
          selected={location.hash === link.url}
          href={link.url}>
          {link.title}
        </MenuButton>
      </LiS>
    ));
  }

  if (!isMenuVisible) return null;
  const checkRole = () => {
    if (user?.roles.includes('teacher')) {
      return 'Teacher';
    } else if (user?.roles.includes('student')) {
      return 'Student';
    } else return 'User';
  };
  return (
    <Box sx={{ minHeight: '64px', height: '64px', width: '100%' }}>
      <AppBar
        position="fixed"
        sx={{
          display: isMenuVisible ? 'flex' : 'none',
          background: 'white',
          height: '64px',
          justifyContent: 'center',
          boxShadow: '0px -7px 7px 7px rgba(0,0,0,0.2)',
        }}
        className="header"
        color="transparent">
        <Toolbar>
          <div style={{ display: 'flex', flex: 0, gap: '4px', justifyContent: 'center', alignItems: 'center' }}>
            {!showInlineMenu && [routeNames.landing, routeNames.signIn, routeNames.signUp].includes(pathname) && (
              <div style={{ display: 'flex' }}>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
                  <MenuIcon />
                </IconButton>
                <Menu id="menu-appbar" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                  <ListS>
                    {LandingPagelinks.map((link) => (
                      <LiS key={link.title} onClick={() => handleMenuClose(link)}>
                        <MenuButton component="a">{link.title}</MenuButton>
                      </LiS>
                    ))}
                  </ListS>
                </Menu>
              </div>
            )}

            <Box>
              <MenuButton
                style={{ fontFamily: 'cursive', fontSize: '24px', minWidth: '20px', display: 'flex', padding: '4px' }}
                to={routeNames.landing}
                title="Home page">
                <Logo />
              </MenuButton>
            </Box>

            {!!user && (
              <Box sx={{ marginX: 1, position: 'relative' }}>
                <LiS style={{ width: showInlineMenu ? spacing(20) : spacing(6) }}>
                  <MenuButton
                    to={routeNames.chat}
                    sx={{
                      gap: 1,
                    }}
                    variant="contained"
                    selected={pathname === routeNames.chat}
                    fullWidth
                    title="My Account">
                    <MessageIcon />
                    {showInlineMenu && <>My Account</>}
                  </MenuButton>
                </LiS>
                {length > 0 && (
                  <Box
                    sx={{
                      bgcolor: 'red',
                      borderRadius: '50%',
                      width: '20px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '20px',
                      position: 'absolute',
                      color: 'white',
                      top: '-15%',
                      right: { xs: '-12%', md: '-5%' },
                    }}>
                    {length}
                  </Box>
                )}
              </Box>
            )}
            {pathname === routeNames.landing && (
              <Box sx={{ marginX: 1 }}>
                <LiS style={{ width: size.width >= 600 ? spacing(22) : spacing(6) }}>
                  <MenuButton
                    to={routeNames.teacherSearch}
                    sx={{
                      gap: 1,
                    }}
                    variant="text"
                    selected={pathname === routeNames.chat}
                    fullWidth
                    title="Teacher Search">
                    <PersonSearchIcon />
                    {size.width >= 600 && <>Teacher Search</>}
                  </MenuButton>
                </LiS>
              </Box>
            )}

            {showInlineMenu && getLandingPageMenuLinks()}
          </div>

          <RightMenuBlockS>
            {user ? (
              <Box>
                <Box sx={{ position: 'relative', cursor: 'pointer' }} aria-label="menu" onClick={handleAccountMenuOpen}>
                  <CustomAvatar size={5} url={user?.photoURL} color={stringToColor(user?.id)} />

                  {user?.roles?.length > 1 && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '65%',
                        right: '-5%',
                        bgcolor: 'white',
                        border: '1px solid #4527a0',
                        py: '1px',
                        px: '2px',
                        borderRadius: '50px',
                      }}>
                      <Typography sx={{ fontSize: '10px', color: '#4527a0', fontWeight: '600' }}>
                        {checkRole()}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Menu
                  id="menu-account-bar"
                  anchorEl={accountMenuAnchorEl}
                  open={Boolean(accountMenuAnchorEl)}
                  onClose={handleAccountMenuClose}>
                  <ListS>{getAccountMenu()}</ListS>
                </Menu>
              </Box>
            ) : (
              pathname !== routeNames.signIn && (
                <LiS key="Sign in">
                  <MenuButton variant="text" to={routeNames.signIn}>
                    <Login />
                    &nbsp;Sign in
                  </MenuButton>
                </LiS>
              )
            )}
          </RightMenuBlockS>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
