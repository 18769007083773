import { policyVersions } from 'containers/PrivacyPage/policyVersions';
import { saveLessonRequest } from 'firebase-db/UserService';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import routeNames from 'route-names';
import { getObjectFromLocalStorage, getStringFromLocalStorage, removeFromLocalStorage } from 'services/localStorage';

import { useAuth } from './useAuth';
import { useQuery } from './useQuery';
import { User } from 'models/User.Class';

export function useRequireAuth(redirectUrl = routeNames.signIn) {
  const auth = useAuth();
  const user: User = auth.user.model;

  const history = useHistory();
  const { invite } = useQuery();
  const location = useLocation();

  const [redirectToURL, setRedirectToURL] = useState('');

  // If auth.user is false that means we're not
  // logged in and should redirect.
  useEffect(() => {
    // User is null while fetching data
    if (auth.user.model === null) return;
    if (!auth.user.model) {
      // Save location to local starage to redirect after registration

      setRedirectToURL(location.pathname);
      localStorage.setItem('initialLocation', location.pathname);
      if (invite) {
        history.push(routeNames.signUp);
      } else {
        history.push(redirectUrl);
      }
    }
    // Agreement
    else if (auth.user.model.hasAgreedToPrivacy.version !== policyVersions[policyVersions.length - 1]) {
      if (!localStorage.getItem('initialLocation')) {
        setRedirectToURL(location.pathname);
        localStorage.setItem('initialLocation', location.pathname);
      }
      history.push(routeNames.privacy);
    }
    // fill up user info
    else if (
      (!auth.user?.model.isTeacher() && !auth.user?.model.isStudent()) ||
      !auth.user?.model.displayName ||
      !auth.user?.model.displaySurname ||
      !auth.user?.model.dateBirth
    ) {
      history.push(routeNames.settingsProfile);
    } else {
      const lessonRequest = getObjectFromLocalStorage('lessonRequest');
      if (lessonRequest) {
        removeFromLocalStorage('lessonRequest');
        const data = { ...lessonRequest, user: auth.user.model.getData() };
        saveLessonRequest({ ...lessonRequest, user: auth.user.model.getData() });
        history.push(routeNames.successLessonRequestPage, data);
      }

      const initialLocation = getStringFromLocalStorage('initialLocation');
      const inviteId = getStringFromLocalStorage('invite');
      if (inviteId) {
        removeFromLocalStorage('invite');
        history.push(routeNames.chat);
      } else if (initialLocation && location.pathname === initialLocation) {
        removeFromLocalStorage('initialLocation');
        setRedirectToURL('');
      } else if (initialLocation && location.pathname !== redirectToURL) {
        history.push(initialLocation);
      }
    }
  }, [auth.user.model, history, location.pathname, redirectUrl]);

  return auth;
}
