import { createRoom, updateParticipantInRooms } from 'firebase-db/RoomsService';
import { User } from 'models/User.Class';
import { getStringFromLocalStorage, removeFromLocalStorage } from 'services/localStorage';
import { saveUserAction } from './ActionsService';
import { handleLogEvent } from './AnalyticsService';
import { firestore } from './firebase';
import { getInviteById } from './InviteService';
import { IUserContactData, IUserData } from 'models/User';
import { RoomTypes } from 'models/Room.Class';
import { snapshotToArray } from './fb-helpers';
import { v4 as uuidv4 } from 'uuid';
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  setDoc,
  startAfter,
  where,
} from 'firebase/firestore';
import { config } from 'config';
import {
  getProgressNumber,
  validateTeacherProfile,
} from 'containers/SettingsProfilePage/services/validateTeacherProfile';

export const collectionUser = 'users';
const collectionConnections = 'connections';

export const getUserRef = (id: string) => doc(firestore, collectionUser, id);

export const getUserConnectionsRef = (id: string) =>
  collection(firestore, `${collectionUser}/${id}/${collectionConnections}`);

export const getUserById = async (id) => {
  if (!id) {
    throw new Error('You must set user id');
  }

  try {
    const userDoc = await getDoc(getUserRef(id));

    if (!userDoc.exists()) {
      console.log('No such document!');
      throw new Error(`There is no user in the database with id ${id}`);
    }

    return new User({ id: userDoc.id, ...(userDoc.data() as User) });
  } catch (error) {
    console.error('getUserById', error);
    throw error;
  }
};

export const getUsersWithRoleTeacher = async (limitNumber, startAfterDoc) => {
  const usersRef = collection(firestore, 'users');

  let q = query(usersRef, where('roles', 'array-contains', 'teacher'), limit(limitNumber));

  if (startAfterDoc) {
    q = query(q, startAfter(startAfterDoc));
  }

  try {
    // const querySnapshot = await getDocs(q);
    // const users = [];
    // querySnapshot.forEach((doc) => {
    //   // Assuming each user document has an 'id' field
    //   users.push(new User({ id: doc.id, ...doc.data() }));
    // });
    // return users;
    const snapshot = await getDocs(q);
    return snapshot.docs.map((doc) => doc.data());
  } catch (error) {
    console.error('Error getting users:', error);
    return [];
  }
};

export const limitPerPage = 10;

export const getAllTeachers = async (
  lastVisible: QueryDocumentSnapshot<DocumentData> | null = null,
  queryParams?: {
    name?: string;
    instrument?: string | null;
    level?: string;
    age?: string;
    location?: string;
    credential?: boolean;
    studio?: boolean;
    online?: boolean;
    inPerson?: boolean;
    minPrice?: number;
    maxPrice?: number;
  },
  itemList: User[] = []
): Promise<{ data: User[]; lastVisible: QueryDocumentSnapshot<DocumentData> | null }> => {
  try {
    let q = query(collection(firestore, 'users'), where('teacher.hasAgreedToBeShownInSearch', '==', true));
    if (lastVisible) {
      q = query(q, startAfter(lastVisible));
    }

    if (queryParams) {
      // Add dynamic query conditions based on queryParams
      // if (queryParams.name) {
      //   q = query(
      //     q,
      //     where('displayName', '>=', queryParams.name.toLowerCase()),
      //     where('displayName', '<=', queryParams.name.toUpperCase() + '\uf8ff')
      //   );
      // }
      // if (queryParams.instrument) {
      //   q = query(q, where('teacher.teacherPreferences', 'array-contains', { name: queryParams.instrument }));
      // } else if (queryParams.level) {
      //   q = query(q, where('teacher.teacherPreferences', 'array-contains', { level: queryParams.level }));
      // } else if (queryParams.age) {
      //   q = query(q, where('teacher.teacherPreferences', 'array-contains', { ageGroup: queryParams.age }));
      // }
      // if (queryParams.location) {
      //   q = query(q, where('location.country', '==', queryParams.location));
      // }
      // if (queryParams.studio) {
      //   q = query(q, where('teacher.school', '==', true));
      // }
      // if (queryParams.online) {
      //   q = query(q, where('teacher.school.online', '==', true));
      // }
      // if (queryParams.inPerson) {
      //   q = query(q, where('teacher.school.inPerson', '==', true));
      // }
      // if (queryParams.minPrice !== undefined && queryParams.maxPrice !== undefined) {
      //   q = query(
      //     q,
      //     where('teacher.pricePerLesson.min', '>=', queryParams.minPrice),
      //     where('teacher.pricePerLesson.max', '<=', queryParams.maxPrice)
      //   );
      // } else if (queryParams.minPrice !== undefined) {
      //   q = query(q, where('teacher.pricePerLesson.min', '>=', queryParams.minPrice));
      // } else if (queryParams.maxPrice !== undefined) {
      //   q = query(q, where('teacher.pricePerLesson.max', '<=', queryParams.maxPrice));
      // }
    }
    if (limitPerPage > 0) {
      q = query(q, limit(limitPerPage));
    }

    // q = query(q, orderBy('updatedAt', 'desc'));

    const snapshot = await getDocs(q);
    let data: User[] = [];
    let newLastVisible: QueryDocumentSnapshot<DocumentData> | null = null;

    snapshot.docs.forEach((doc) => {
      const newUser = new User({ id: doc.id, ...doc.data() } as IUserData);
      if (validateTeacherProfile(newUser)) {
        data.push(newUser);
      }
    });

    // Set last visible document for pagination
    if (!snapshot.empty) {
      newLastVisible = snapshot.docs[snapshot.docs.length - 1];
    }

    if (data.length + (itemList?.length || 0) < limitPerPage && newLastVisible) {
      const nextData = await getAllTeachers(newLastVisible, queryParams, data);
      data = [...data, ...nextData.data];
      newLastVisible = nextData.lastVisible;
    }

    const sortedData = data.sort(
      (a: User, b: User) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    );
    const sortedByProfileFullfilment = data.sort((a: User, b: User) => getProgressNumber(b) - getProgressNumber(a));

    return { data: sortedByProfileFullfilment, lastVisible: newLastVisible };
  } catch (error) {
    console.error('getAllTeachers', error);
    return { data: [], lastVisible: null };
  }
};

export const getChatContactUser = async () => {
  const email = config.chat.contactEmail;

  const usersCollection = collection(firestore, collectionUser);
  const q = query(usersCollection, where('email', '==', email));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    console.log('No such document!');
    return null;
  } else {
    const bot = new User({ id: querySnapshot.docs[0].id, ...(querySnapshot.docs[0].data() as User) });
    bot.makeTeacher();
    return bot;
  }
};
export async function getUsersPlans(userIds) {
  try {
    const subscriptionPlans = [];

    for (const userId of userIds) {
      const userDocRef = doc(firestore, 'users', userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        subscriptionPlans.push(userData.plan);
      } else {
        console.log(`Not found ${userId} `);
      }
    }

    return subscriptionPlans.some((item) => item.toLowerCase() === 'go');
  } catch (error) {
    console.error(error);
  }
}

export function shouldUpdateContactsAndRooms(user, prevUser: IUserContactData) {
  if (user?.displayName !== prevUser?.displayName) {
    return true;
  }
  if (user?.displaySurname !== prevUser?.displaySurname) {
    return true;
  }
  if (user?.email !== prevUser?.email) return true;
  if (user?.photoURL !== prevUser?.photoURL) return true;
  if (user.roles?.length === prevUser.roles?.length) {
    const userRolesSet = new Set(user.roles);
    const haveSameValues = prevUser.roles.every((value) => userRolesSet.has(value));
    if (!haveSameValues) return true;
  }
  return false;
}

export const saveUser = async (user, options = { merge: true }) => {
  try {
    if (!user || !user.id) {
      throw new Error('Set user id');
    }

    let shouldUpdate = false;
    const userDoc = await getDoc(getUserRef(user.id));

    if (userDoc.exists()) {
      const prevUser = new User({ id: user.id, ...(userDoc.data() as User) });
      shouldUpdate = shouldUpdateContactsAndRooms(user, prevUser.getUserContactData());
    }

    await setDoc(getUserRef(user.id), { ...user, updatedAt: new Date().toISOString() }, options);
    const nextUserDoc = await getDoc(getUserRef(user.id));

    if (!nextUserDoc.exists()) {
      throw new Error('saveUser: Can not find user: ' + user.id);
    }

    const nextUser = new User({ id: user.id, ...(nextUserDoc.data() as User) });

    if (shouldUpdate) {
      await Promise.all([
        updateParticipantInRooms(nextUser.getUserParticipantData()),
        // updateUserDataInContacts(nextUser),
      ]);
    }
    return nextUser;
  } catch (error) {
    console.error('saveUser', error);
    throw error;
  }
};
export const addUnread = async (user, messageId, roomId) => {
  const realUser = new User(await getUserById(user.id));

  const newUnread = [...realUser.getData().unread, { message: messageId, room: roomId, unreadId: uuidv4() }];

  const userData = {
    ...realUser.getData(),
    unread: newUnread,
  };
  saveUser(userData);
};

// export const updateUserDataInContacts = async (user) => {
//   try {
//     const connections = await getUserConnections(user.id);

//     if (connections && connections.length > 0) {
//       const updatePromises = connections.map(async (connection) => {
//         const connectionRef = doc(
//           collection(firestore, `${collectionUser}/${connection.id}/${collectionConnections}`),
//           user.id
//         );
//         await setDoc(connectionRef, user.getUserContactData(), { merge: true });
//       });

//       await Promise.all(updatePromises);
//     }
//   } catch (error) {
//     console.error('updateUserDataInContacts', error);
//     throw error;
//   }
// };

export const createUserIfNew = async (authUser): Promise<{ data: User | null; error: Error | null }> => {
  let data: User | null = null;
  let error: Error | null = null;

  try {
    if (!authUser) {
      throw new Error('Set authUser for createUserIfNew');
    }

    const userDoc = await getDoc(getUserRef(authUser.uid));
    const inviteId = getStringFromLocalStorage('invite');

    if (!userDoc.exists()) {
      // Create user if it does not exist
      const ref = getStringFromLocalStorage('ref') || inviteId;

      const firstName = authUser.displayName?.split(' ')[0].trim() || '';
      const lastName = authUser.displayName?.split(' ')?.pop().trim() || '';
      const userData = {
        id: authUser.uid,
        email: authUser.email,
        displayName: firstName,
        displaySurname: lastName !== firstName ? lastName : '',
        photoURL: authUser.photoURL,
        emailVerified: authUser.emailVerified,
        phoneNumber: authUser.phoneNumber,
        createdAt: new Date().toISOString(),
        ref,
      };

      const newUserData = new User(userData as any).getData();

      await saveUserAction({
        name: 'create_new_user',
        user: { id: newUserData.id, email: newUserData.email, roles: newUserData.roles },
      });
      handleLogEvent('create_new_user');

      if (inviteId) {
        data = await saveUser(newUserData);
        if (data) {
          const invite = await getInviteById(inviteId);
          if (!invite?.userId) {
            throw new Error(`Can not find invite with id: ${inviteId} and create a room for user: ${data.id}`);
          }
          if (invite?.userId !== data.id) {
            await createRoomForInvitedUser(data, invite.userId);
          }
          removeFromLocalStorage('ref');
        }
      } else {
        data = await saveUser(newUserData);
      }
    } else {
      // Log in user if it already exists
      await saveUserAction({
        name: 'login',
        user: { id: userDoc.id, email: userDoc.data().email, roles: userDoc.data().roles },
      });
      handleLogEvent('login');
      data = new User({ id: userDoc.id, ...(userDoc.data() as User) });

      // Create room for Users who came by invite
      if (inviteId) {
        const invite = await getInviteById(inviteId);
        if (!invite?.userId) {
          throw new Error(`Can not find invite with id: ${inviteId} and create a room for user: ${data.id}`);
        }
        if (invite?.userId !== data.id) {
          await createRoomForInvitedUser(data, invite.userId);
        }
      }
    }
  } catch (err) {
    console.error('createUserIfNew', err);
    error = err instanceof Error ? err : new Error('An unknown error occurred');
  }

  return { data, error };
};

export const createRoomForInvitedUser = async function (user: User, inviteId: string) {
  try {
    const inviteUser = await getUserById(inviteId);
    if (!user) throw new Error('createRoomForInvitedUser: Provide User');
    if (!inviteUser) throw new Error('createRoomForInvitedUser: Can not find user by id: ' + inviteId);
    if (user.id === inviteId) return;

    // const contactDoc = await getDoc(doc(getUserConnectionsRef(user.id), inviteUser.id));
    // let room;
    // if (contactDoc.exists()) {
    //   const roomId = contactDoc.data().roomId;
    //   room = await getRoomById(roomId);
    //   if (room) {
    //     return await Promise.all([
    //       setUserConnections(user.id, inviteUser.getUserContactData(), roomId),
    //       setUserConnections(inviteUser.id, user.getUserContactData(), roomId),
    //     ]);
    //   }
    // }

    const roomDoc = await createRoom({
      type: RoomTypes.private,
      participantIds: [inviteUser.id, user.id],
      participants: [inviteUser.getUserParticipantData(), user.getUserParticipantData()],
    });

    // if (roomDoc?.id) {
    //   return await Promise.all([
    //     setUserConnections(user.id, inviteUser.getUserContactData(), roomDoc.id),
    //     setUserConnections(inviteUser.id, user.getUserContactData(), roomDoc.id),
    //   ]);
    // } else {
    //   throw new Error('createRoomForInvitedUser: Not enough data');
    // }
  } catch (e) {
    console.error('createRoomForInvitedUser', e);
  }
};

export const getAllUsers = async (snapshot?): Promise<any> => {
  try {
    if (!snapshot) {
      snapshot = await getDocs(collection(firestore, collectionUser));
    }

    return snapshotToArray(snapshot).map((u) => new User(u));
  } catch (error) {
    console.error('getAllUsers', error);
  }
};

// export const getUserConnections = async (id) => {
//   try {
//     const docRef = doc(firestore, collectionConnections, id);
//     const snapshot = await getDoc(docRef);
//     if (snapshot.exists()) {
//       return snapshot.data() as IUserContactData[];
//     } else {
//       console.log('No such document!');
//       return null;
//     }
//   } catch (error) {
//     console.error('getUserConnections', error);
//   }
// };

// export const setUserConnections = async (id: string, contact: IUserContactData, roomId: string): Promise<any> => {
//   const communication = { ...contact, roomId: roomId };
//   try {
//     await setDoc(doc(firestore, collectionConnections, id, 'communications', communication.id), communication);

//     return communication;
//   } catch (e) {
//     console.error('setUserConnections', e);
//   }
// };

// export const deleteUserConnection = async (id, contactId) => {
//   try {
//     await deleteDoc(doc(firestore, collectionConnections, id, 'communications', contactId));
//     console.log('Connection deleted');
//   } catch (error) {
//     console.error('Can not delete connection', error);
//   }
// };

export const saveLessonRequest = async (data) => {
  if (!data) {
    return Promise.reject(new Error('Set user id'));
  }
  try {
    if (data.id) {
      await setDoc(doc(firestore, 'lessonRequests', data.id), { ...data, createdAt: new Date().toISOString() });
      return data.id;
    } else {
      const res = await addDoc(collection(firestore, 'lessonRequests'), { ...data, created: new Date().toISOString() });
      return res.id;
    }
  } catch (error) {
    console.error('Error saving lesson request:', error);
    throw error;
  }
};

// export async function fixDB() {
//   try {
//     const userDocs = await firestore.collection('users').get();
//     const users = snapshotToArray(userDocs).map((u) => new User(u));
//     const createdRooms = {};
//     const usersMap = new Map();
//     users.forEach((u) => usersMap.set(u.id, u));

//     for (const user of users) {
//       if (!user.id) {
//         console.error('Can not find user id', user.id);
//         return;
//       }
//       const connections = await getUserConnections(user.id);

//       for (const connection of connections) {
//         const uuid = [user.id, connection.id].sort().join('-');
//         console.log(uuid, user.displayName, connection.displayName);
//         if (!createdRooms[uuid] && usersMap.has(connection.id)) {
//           const roomDoc = await createRoom({
//             type: RoomTypes.private,
//             participantIds: [user.id, connection.id].sort(),
//             participants: [user.getUserParticipantData(), usersMap.get(connection.id).getUserParticipantData()],
//           });
//           createdRooms[uuid] = true;
//           if (roomDoc?.id) {
//             await setUserConnections(user.id, usersMap.get(connection.id).getUserParticipantData(), roomDoc.id);
//             await setUserConnections(connection.id, usersMap.get(user.id).getUserParticipantData(), roomDoc.id);
//           }
//         }

//         console.log('connection', connection);
//         await new Promise((resolve) => setTimeout(resolve, 500));
//       }
//     }
//   } catch (e) {
//     console.error(e);
//   }
// }

async function fixUsersDataInEveryRoom() {
  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  try {
    const userCollectionRef = query(collection(firestore, 'users'));

    // Fetch all users from the collection
    const querySnapshot = await getDocs(userCollectionRef);

    for (const doc of querySnapshot.docs) {
      const user = new User({ id: doc.id, ...(doc.data() as IUserData) });
      await updateParticipantInRooms(user.getUserParticipantData());
      await delay(200);
    }

    console.log('All users data updated in every room successfully');
  } catch (error) {
    console.error('Error updating users data in rooms:', error);
  }
}
