import { Avatar, Box, Link, Typography } from '@mui/material';
import React from 'react';

import { ProfilePageComponentWrapper } from '../ProfilePageComponentWrapper';
import { Description, SubDescription, Subtitle, Title } from './Typography';
import { ProfileItemInfo } from './ProfileItemInfo';

export function ProfileReusableInfoCard({
  title,
  data,
}: {
  title: string;
  data: {
    label?: string;
    subLabel?: string;
    downLabel?: string;
    additionalLabel?: string;
    link?: string;
  }[];
}) {
  if (!data?.length) return null;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: '16px', md: '24px' } }}>
      <Title>{title}</Title>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: '24px', md: '32px' } }}>
        {data?.map((item) => (
          <ProfileItemInfo
            label={item.label}
            subLabel={item.subLabel}
            downLabel={item.downLabel}
            link={item.link}
            additionalLabel={item.additionalLabel}
          />
        ))}
      </Box>
    </Box>
  );
}
